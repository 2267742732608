@import url('https://fonts.googleapis.com/css2?family=Duplet+Rounded:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Blatant:wght@400;700&display=swap');

@font-face {
    font-family: 'Duplet Rounded Regular';
    src: url('../fonts/DupletRounded-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Duplet Rounded Bold';
    src: url('../fonts/DupletRounded-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Blatant Bold';
    src: url('../fonts/Blatant-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
  }

body {
    margin: 0;
    background: radial-gradient(70.74% 31.36% at 9.3% 97.23%, #28AD7E 0%, rgba(40, 173, 126, 0) 100%),
    radial-gradient(61.69% 26.79% at 100% 48.59%, #28AD7E 0%, rgba(40, 173, 126, 0) 100%),
    radial-gradient(57.42% 25.46% at 4.64% 3.14%, #28AD7E 0%, rgba(40, 173, 126, 0) 100%), #122F30;
}
a {
    font-family: 'Duplet Rounded Regular', sans-serif;
}
h1, h2, h3 {
    font-family: 'Duplet Rounded Regular', sans-serif;
    color: #ffffff;
}

p {
    font-family: 'Duplet Rounded Regular', sans-serif;
}

button {
    color: #FFFFFF;
    font-family: 'Duplet Rounded Regular', sans-serif;
    font-size: 26px;
}

details summary {
    list-style: none;
  }
  
  details summary::-webkit-details-marker {
    display: none;
  }
  
  html {
    scroll-behavior: smooth;
  }

.bg-gradient-to-r {
            background-image: linear-gradient(to right, var(--tw-gradient-stops));
        }
        .from-green-400 {
            --tw-gradient-from: #34d399;
            --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0));
        }
        .to-gray-900 {
            --tw-gradient-to: #1f2937;
        }

input, textarea {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
}

input:focus, textarea:focus {
    border-color: #af784c;
    outline: none;
}